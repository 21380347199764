.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.adminLogo {
  margin: 100px 0 32px;
}

.form {
  display: flex;
  align-items: flex-start;
  gap: 8px; /* TODO: supports для gap */
}

@media (min-width: 1200px) {
  .main {
    flex: 1;
    justify-content: center;
  }

  .adminLogo {
    margin-top: 0;
  }
}
