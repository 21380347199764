.wrapper {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.wrapper > svg {
  margin: -16px;
}

.price {
  position: absolute;
  color: var(--black-hover);
}
