.header {
  display: flex;
  align-items: center;
  gap: 8px; /* TODO: написать supports для gap */
  position: sticky;
  top: 0;
  z-index: 200;
  padding: 16px;
  padding-right: 0;
  height: 64px;
  backdrop-filter: brightness(0); /* TODO: написать supports для backdrop */
  background-color: rgba(37, 37, 37, 0.5);
}

.title {
  /* flex: 1; */
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.menu {
  display: none;
}

@media (min-width: 1200px) {
  .header {
    justify-content: center;
  }

  .login > svg {
    width: 190px;
    height: 45px;
  }

  .header > button:first-of-type {
    display: none;
  }

  .logo,
  .title {
    display: none;
  }

  .menu {
    display: inline-block;
  }
}
