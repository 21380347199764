.headerWrapper {
  position: sticky;
  top: 0;
  z-index: 100;
}

.main {
  display: grid;
  row-gap: 16px;
  column-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  padding: 32px 20px 50px;
  overflow-x: hidden;
}

@media (min-width: 1200px) {
  .headerWrapper {
    display: flex;
    justify-content: center;
  }

  .header {
    position: absolute !important;
    z-index: 300 !important;
    padding: 0 !important;
    background: none !important;
    backdrop-filter: none !important;
  }

  .main {
    padding: 32px 124px 112px;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 90px;
    column-gap: 72px;
  }

  .filters {
    width: 100% !important;
  }
}

@media (min-width: 1600px) {
  .main {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 2000px) {
  .main {
    grid-template-columns: repeat(5, 1fr);
  }
}
