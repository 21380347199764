.main {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.backButton {
  display: none !important;
}

.title {
  margin-bottom: 24px !important;
}

.mainContent {
  display: flex;
  flex-direction: column;
}

.subtitle {
  margin-bottom: 16px !important;
}

.promt {
  margin-bottom: 8px !important;
}

.untappd,
.untappdWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.input {
  margin-bottom: 0 !important;
  flex: 1 !important;
}

.manualAction {
  color: var(--blue) !important;
}

@media (min-width: 1200px) {
  .main {
    padding: 24px 120px;
  }

  .backButton {
    display: flex !important;
    margin-bottom: 8px !important;
    padding-left: 0;
    padding-right: 4px;
    width: max-content;
  }

  .mainContent {
    width: max-content;
  }

  .subtitle {
    margin-bottom: 24px !important;
  }

  .untappdWrapper {
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
  }
}

/* TODO: ниже дублирующиеся стили для штрих-кода из Edit */
.barcodeWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.barcode {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Libre Barcode EAN13 Text";
  font-feature-settings: "calt" 1;
  font-weight: 400;
  font-size: 120px;
  line-height: 120px;
  padding: 22px 0;
  color: var(--black);
  background-color: var(--white);
  border-radius: 4px;
}

.emptyBarcode {
  font-family: Lab Grotesque, -apple-system, BlinkMacSystemFont, Arial,
    Liberation Sans, Nimbus Sans L, sans-serif;
  font-size: 32px;
  color: var(--white-inactive);
}