.wrapper {
  border: 1px solid var(--white-inactive);
  border-radius: 8px;
  overflow: hidden;
}

.wrapper a {
  text-decoration: none;
  color: inherit;
}

.photo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 180px;
  color: var(--black);
  background-color: var(--white);
}

.photo > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  display: flex;
  flex-direction: column;
  padding: 12px 16px 16px;
  height: 200px;
}

.name {
  flex: 1;
  padding-bottom: 12px;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--white-inactive);
  overflow: hidden;
}

.otherInfo {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 1200px) {
  .name {
    margin-bottom: 16px;
  }
}
